// general constants

export const MINUTES_IN_AN_HOUR = 60;
export const HOURS_IN_A_DAY = 24;

// driver constants

export const MONTHLY_NOMINAL_HOURS = 169;
export const WEEKLY_NOMINAL_HOURS = 40;
export const MAX_NON_WORK_TO_WORK_RATIO = 0.47;

export const MIN_DRIVER_REST_TIME_HOURS = 9;
export const MAX_DRIVER_WORK_GROUP_DURATION_HOURS = 15;

// defaults

export const DEFAULT_DEBOUNCE_DELAY = 500;